<template>
  <common-trs-page-container :padding="0">
    <template v-slot:page-content>
      <v-card-title class="mt-3 pt-6 pl-8">
        <span
          class="pr-3 cursor-pointer"
          @click="close"
        >
          <v-icon> mdi-arrow-left</v-icon></span>
        <span
          class="trsText--text font-weight-medium text-h4"
        >Invite Holder</span>
      </v-card-title>
      <common-page-loader v-if="loading" />
      <common-trs-card
        class="mx-6 pb-0 mb-2"
        style="max-width: 400px"
      >
        <validation-observer
          ref="observer"
          v-slot="{ valid }"
          class="ml-auto"
        >
          <v-card-text class="px-0">
            <holder-form
              v-model="holderData"
              :editable="false"
            />
          </v-card-text>

          <v-row class="mx-6">
            <v-divider />
          </v-row>
          <v-row class="d-flex justify-space-between mx-6">
            <v-col class="pl-0 pt-0">
              <common-trs-btn
                type="secondary"
                medium
                class="pull-right mr-2"
                text
                :to="{ name: 'ManageHolders' }"
              >
                Cancel
              </common-trs-btn>
            </v-col>
            <v-col class="text-right pl-0 pt-0 pr-0">
              <common-trs-btn
                type="primary"
                medium
                class="white--text"
                text
                :disabled="!valid"
                :loading="loading"
                @click="save"
              >
                Invite
              </common-trs-btn>
            </v-col>
          </v-row>
        </validation-observer>
      </common-trs-card>
    </template>
  </common-trs-page-container>
</template>

<script>
  import HolderForm from '@/components/common/captable/components/forms/HolderForm'
  import * as captableAdminService from '@/services/captable-admin-service'

  export default {
    name: 'InviteHolder',

    components: {
      HolderForm,
    },

    data: () => ({
      holderData: {},
      loading: false,
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
      currentOrganization () {
        return this.$store.getters['auth/currentOrganization']
      },
    },

    methods: {
      async save () {
        this.loading = true
        try {
          await captableAdminService.inviteHolder(this.captableId, {
            ...this.holderData,
            is_security_issued: false,
          })
          this.loading = false
          this.$store.dispatch('app/message', {
            text: 'Holder is created successfully.',
          })
          this.$router.push({ name: 'ManageHolders' })
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to create the holder. Please try again later.',
          })
        }
      },
      close () {
        this.$router.push({ name: 'ManageHolders' })
      },
    },
  }
</script>
