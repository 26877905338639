<template>
  <v-form ref="form">
    <v-row
      class="ma-0 mt-4"
    >
      <v-col
        cols="12"
        class="py-0"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Holder name'"
          :rules="{ required: true, noNumbers: true, noSymbols: true, max: 30 }"
        >
          <common-input-text-field
            v-model="internalValue.name"
            label="Holder Name"
            color="primary"
            :error-messages="errors"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Holder ID'"
          :rules="{ required: true, alpha_dash: true }"
        >
          <common-input-text-field
            v-model="internalValue.holder_id"
            label="Holder ID"
            color="primary"
            :error-messages="errors"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Holder email'"
          :rules="{ required: true, email: true }"
        >
          <common-input-text-field
            v-model="internalValue.username"
            label="Holder Email"
            color="primary"
            :error-messages="errors"
          />
        </validation-provider>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>

  export default {
    name: 'ScenarioForm',

    components: {
    },
    props: {
      value: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
    }),

    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    watch: {
    },

    methods: {
    },
  }
</script>
<style lang="scss" scoped>
.v-input--selection-controls {
  margin-top: 0 !important;
}
.grace-period-row {
  line-height: 55px;
  background: var(--v-accent-base);
  border-radius: 4px;
}
</style>
